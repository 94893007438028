.custom-calendar {
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-family: system-ui, -apple-system, sans-serif;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: white;
  padding: 1rem;
}

/* Remove inner border */
.date-picker-custom {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-calendar .react-datepicker__header {
  background-color: white;
  border-bottom: 1px solid #e2e8f0;
  padding-top: 0.75rem;
}

.custom-calendar .react-datepicker__current-month {
  color: #1a202c;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.custom-calendar .react-datepicker__day-name {
  color: #4a5568;
  width: 2rem;
  margin: 0.2rem;
}

.custom-calendar .react-datepicker__day {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: 0.2rem;
  border-radius: 9999px;
  color: #4a5568;
}

.custom-calendar .react-datepicker__day:hover {
  background-color: #edf2f7;
}

.custom-calendar .react-datepicker__day--selected {
  background-color: #4f46e5;
  color: white;
}

.custom-calendar .react-datepicker__day--keyboard-selected {
  background-color: #4f46e5;
  color: white;
}

.custom-calendar .react-datepicker__day--disabled {
  color: #cbd5e0;
}

.custom-calendar .react-datepicker__navigation {
  top: 1rem;
}

.custom-calendar .react-datepicker__month-select,
.custom-calendar .react-datepicker__year-select {
  background-color: #f7fafc;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
}

.custom-calendar .react-datepicker__month-select:focus,
.custom-calendar .react-datepicker__year-select:focus {
  outline: none;
  border-color: #4f46e5;
}
