@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Roboto:wght@300;400;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
}

/* Prevent horizontal movement */
html, body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Ensure all fixed and absolute positioned elements stay within viewport */
.fixed, .absolute {
  max-width: 100vw;
}